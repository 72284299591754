import React, { useContext, useState, useEffect }  from 'react';
import { useStaticQuery, graphql, navigate, Link } from 'gatsby';

// import constants
import { AUTH_URL, CHANGE_LOGIN_STATUS } from '../constants';

// import context
import { GlobalDispatchContext } from '../context/GlobalContextProvider';

// import helpers
import { useWindowSize, postData, usePasswordValidation, useEmailValidation, setCookie } from '../helpers';

// import utils
import { setAccessToken, setRefreshToken, setUser } from '../utils/Auth';

// import styles
import { ResetPasswordElement } from '../components/elements/ResetPassword/styles/ResetPasswordForm';
import { MobileMenuElement }    from '../components/elements/MobileMenu/styles';

// import components
import UserIconCircled   from '../components/elements/icons/UserIconCircled';
import EyeVisibleOffIcon from '../components/elements/icons/EyeVisibleOffIcon';
import EyeVisibleOnIcon  from '../components/elements/icons/EyeVisibleOnIcon';
import Button            from '../components/elements/Button';
import CloseBtn          from '../components/elements/CloseBtn';
import BrandIconExtended from '../components/elements/icons/BrandIconExtended';
import UserIcon          from '../components/elements/icons/UserIcon';
import Seo               from '../components/common/Seo';

const VerifyEmailPage = () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          verify_email {
            title
            thank_msg
            login_btn_text
            expired_title
            expired_text
            expired_email_placeholder
            expired_button_text
          }
          
          login {
            error
            remember_text
            fields {
              text
              type
            }
          }
          
          register {
            thank_msg
          }
          
          powereds {
            link_text
            title_powered
          }
        }
      }
    }
  `);

  // define the component context
  const dispatch = useContext( GlobalDispatchContext );

  // define the default component state
  const [loginPasswordIsInvalid, setLoginPasswordIsInvalid] = useState( false );
  const [passwordIsVisible, setPasswordIsVisible] = useState( false );
  const [emailIsInvalid, setEmailIsInvalid] = useState( false );
  const [isBtnProcess, setIsBtnProcess] = useState( false );
  const [tokenExpired, setTokenExpired] = useState( false );
  const [isSuccess, setIsSuccess] = useState( false );
  const [isError, setIsError] = useState( false );
  const [texts, setTexts] = useState({
    verifyTitle: '',
    verifyMsg: '',
    verifyBtnText: '',
    loginError: '',
    loginRememberText: '',
    loginFields: [],
    poweredText: '',
    poweredLink: '',
    successText: '',
    expiredTitle: '',
    expiredText: '',
    expiredEmailPlaceholder: '',
    expiredBtnText: ''
  });

  const {
    verifyTitle, verifyMsg, verifyBtnText, loginError, loginRememberText, loginFields, poweredText, poweredLink,
    successText, expiredTitle, expiredText, expiredEmailPlaceholder, expiredBtnText
  } = texts;

  const [loginFormData, setLoginFormData] = useState({
    loginEmail: '',
    loginPassword: '',
    loginRemember: false
  });

  const { loginEmail, loginPassword, loginRemember } = loginFormData;

  // define the component custom hooks
  const passwordValidation = usePasswordValidation( loginPassword );
  const emailValidation    = useEmailValidation( loginEmail );
  const windowSize = useWindowSize();
  const { width } = windowSize;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { verify_email, login, powereds, register } = node;
      const currentVerify  = verify_email[0];
      const currentLogin   = login[0];
      const currentPowered = powereds[0];
      const { error: loginError, fields: loginFields, remember_text } = currentLogin;
      const { link_text, title_powered } = currentPowered;
      const {
        title, thank_msg, login_btn_text, expired_title, expired_text, expired_email_placeholder, expired_button_text
      } = currentVerify;

      setTexts( t => ({
        ...t,
        verifyTitle: title,
        verifyMsg: thank_msg,
        verifyBtnText: login_btn_text,
        loginRememberText: remember_text,
        loginError,
        loginFields,
        poweredText: title_powered,
        poweredLink: link_text,
        successText: register[0].thank_msg,
        expiredTitle: expired_title,
        expiredText: expired_text,
        expiredEmailPlaceholder: expired_email_placeholder,
        expiredBtnText: expired_button_text
      }));
    });
  }, [data] );

  /**
   * Sending the verification token
   */
  useEffect( () => {
    const getParams = new URLSearchParams( window.location.search );
    const token     = getParams.get( 'token' );

    if ( token === null || token === undefined ) {
      navigate( '/' );
    } else {
      postData( AUTH_URL + 'verify-email/', 'POST', { token }, false )
        .then( data => {
          const response = data[0];
          const { status } = response;

          // if the token is invalid or the token is undefined
          if ( status === 400 ) {
            navigate( '/' );
          }

          // if the token expired
          if ( status === 409 ) {
            setTokenExpired( true );
          }
        });
    }
  }, [] );

  /**
   * Processing the Login action after email confirmation
   *
   * @param e | Event
   */
  const handleLogin = ( e ) => {
    e.preventDefault();

    const { loginEmail, loginPassword, loginRemember } = loginFormData;

    if (
      loginEmail !== '' && emailValidation &&
      loginPassword !== '' && passwordValidation
    ) {
      const data = {
        email: loginEmail,
        password: loginPassword
      };

      setIsBtnProcess( true );

      postData( AUTH_URL + 'login/', 'POST', data, false )
        .then( data => {
          const response = data[0];
          const { status } = response;

          // if the user successfully logged in
          if ( status === 200 ) {
            const resJson = data[1];
            const { tokens, username, email: userEmail, company_name, first_name, last_name } = resJson;

            const preparedTokens = tokens.replace( /'/g, '"' );
            const parsedTokens   = JSON.parse( preparedTokens );
            const accessToken    = parsedTokens.access;
            const refreshToken   = parsedTokens.refresh;

            const userData = {
              email: userEmail,
              username,
              firstName: first_name,
              lastName: last_name,
              companyName: company_name
            };

            if ( loginRemember ) {
              setCookie( 'MMBlogSession', true, { 'max-age': 24 * 60 * 60 * 7 } );
              setAccessToken( accessToken, true );
              setRefreshToken( refreshToken, true );
              setUser( userData, true );
            } else {
              setAccessToken( accessToken, false );
              setRefreshToken( refreshToken, false );
              setUser( userData, false );
            }

            dispatch( { type: CHANGE_LOGIN_STATUS, payload: true } );

            navigate( '/' );
          }

          // if the email or password is invalid
          if ( status === 401 ) {
            setIsBtnProcess( false );
            setIsError( true );
            setEmailIsInvalid( true );
            setLoginPasswordIsInvalid( true );
          }
        });
    } else {
      setIsError( true );

      if ( loginEmail === '' || !emailValidation ) setEmailIsInvalid( true );
      if ( loginPassword === '' || !passwordValidation ) setLoginPasswordIsInvalid( true );
    }
  };

  /**
   * Processing fill a field
   *
   * @param e | Event
   * @param index | int
   */
  const handleChangeLoginField = ( e, index ) => {
    switch ( index ) {
      case 1:
        setLoginFormData( { ...loginFormData, loginPassword: e.currentTarget.value.trim() } );
        break;

      default:
        setLoginFormData( { ...loginFormData, loginEmail: e.currentTarget.value.trim().toLowerCase() } );
    }
  };

  /**
   * Hide an error
   */
  const handleHideError = () => {
    if ( isError ) setIsError( false );
    if ( emailIsInvalid ) setEmailIsInvalid( false );
    if ( loginPasswordIsInvalid ) setLoginPasswordIsInvalid( false );
  };

  /**
   * Get a new activation link
   *
   * @param e | Event
   */
  const handleResetActivationLink = ( e ) => {
    e.preventDefault();

    const { loginEmail } = loginFormData;

    if ( loginEmail !== '' && emailValidation ) {
      const data = {
        language: 'de',
        email: loginEmail
      };

      setIsBtnProcess( true );

      postData( AUTH_URL + 'verify-email-again/', 'POST', data, false )
        .then( data => {
          if ( data[0].status === 200 ) {
            setIsBtnProcess( false );
            setIsSuccess( true );
          }
        });
    } else {
      setIsError( true );

      if ( loginEmail === '' || !emailValidation ) setEmailIsInvalid( true );
    }
  };

  return (
    <>
      <Seo title={ verifyTitle } />
      <ResetPasswordElement>
        { width > 991 ?
          <>
            <UserIconCircled className="icon" />
            <h4 className="title">{ tokenExpired ? expiredTitle : verifyTitle }</h4>
          </>
        :
          <MobileMenuElement className="is-opened">
            <div className="top">
              <div className="header">
                <Link className="logo__link" to="/">
                  <BrandIconExtended className="logo" color="#140048" />
                  <span className="logo__text">{ `${ poweredText } ${ poweredLink }` }</span>
                </Link>
              </div>

              <div className="switcher">
                <div className="switcher__user is-extended">
                  <UserIcon className="switcher__user__icon" color="#431e75" />
                </div>
              </div>
            </div>
          </MobileMenuElement>
        }

        { tokenExpired ?
          isSuccess ?
            <p className="description" dangerouslySetInnerHTML={ { __html: successText } } />
          :
            <p className="description" dangerouslySetInnerHTML={ { __html: expiredText } } />
        :
          <p className="description" dangerouslySetInnerHTML={ { __html: verifyMsg } } />
        }
        { isError ? <span className="error">{ loginError }</span> : false }

        { tokenExpired ?
          !isSuccess ?
            <form className="form"
                  onSubmit={ ( e ) => handleResetActivationLink( e ) }>
              <div className="field-group">
                <input className={ emailIsInvalid ? 'field field--is-invalid' : 'field' }
                       type="email"
                       value={ loginEmail }
                       placeholder={ expiredEmailPlaceholder }
                       onChange={ ( e ) => handleChangeLoginField( e, 0 ) }
                       onFocus={ handleHideError } />
              </div>

              <Button className={ `btn${ isBtnProcess ? ' processing' : '' }` }
                      text={ expiredBtnText }
                      disabled={ isBtnProcess }
                      method="submit" />
            </form>
          : false
        :
          <form className="form"
                onSubmit={ ( e ) => handleLogin( e ) }>
            { loginFields.length > 0 ?
              loginFields.map( ( field, index ) => {
                const { text, type } = field;
                let value;

                switch ( index ) {
                  case 1:
                    value = loginPassword;
                    break;

                  default:
                    value = loginEmail;
                }

                return (
                  <div className="field-group" key={ type }>
                    { type === 'password' ?
                      <>
                        <input className={ loginPasswordIsInvalid ? 'field field--is-invalid' : 'field' }
                               type={ passwordIsVisible ? 'text' : 'password' }
                               value={ value }
                               placeholder={ text }
                               onChange={ ( e ) => handleChangeLoginField( e, index ) }
                               onFocus={ handleHideError } />
                        <span className="field__icon"
                              role="presentation"
                              onClick={ () => setPasswordIsVisible( v => !v ) }>
                          { value !== '' ?
                            passwordIsVisible ?
                              <EyeVisibleOffIcon className="field__icon__item" />
                            :
                              <EyeVisibleOnIcon className="field__icon__item" />
                          : false }
                        </span>
                      </>
                    :
                      <input className={ emailIsInvalid ? 'field field--is-invalid' : 'field' }
                             type={ type }
                             value={ value }
                             placeholder={ text }
                             onChange={ ( e ) => handleChangeLoginField( e, index ) }
                             onFocus={ handleHideError } />
                    }
                  </div>
                )
              })
            : false }

            <div className="remember">
              { width > 991 ?
                <>
                  <input className="remember__mark"
                         id="remember"
                         type="checkbox"
                         checked={ loginRemember }
                         hidden
                         onChange={ ( e ) => { setLoginFormData( { ...loginFormData, loginRemember: e.currentTarget.checked } ) } } />
                  <label className="remember__label"
                         htmlFor="remember">
                    { loginRememberText }
                  </label>
                </>
              :
                <>
                  <input className="remember__mark"
                         id="remember"
                         type="checkbox"
                         checked={ loginRemember }
                         hidden
                         onChange={ () => setLoginFormData( ( state ) => ( { ...loginFormData, loginRemember: !state.loginRemember } ) ) } />
                  <label className={ loginRemember ? 'remember__label is-checked' : 'remember__label' }
                         htmlFor="remember"
                         role="presentation"
                         onClick={ () => setLoginFormData( ( state ) => ( { ...loginFormData, loginRemember: !state.loginRemember } ) ) }>
                    { loginRememberText }
                  </label>
                </>
              }
            </div>

            <Button className={ `btn${ isBtnProcess ? ' processing' : '' }` }
                    text={ verifyBtnText }
                    disabled={ isBtnProcess }
                    method="submit" />
          </form>
        }

        <CloseBtn className="close" onClick={ () => navigate( '/' ) } />
      </ResetPasswordElement>
    </>
  )
};

export default VerifyEmailPage;